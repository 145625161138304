var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// https://d3js.org/d3-path/ v1.0.9 Copyright 2019 Mike Bostock
(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var pi = Math.PI,
      tau = 2 * pi,
      epsilon = 0.000001,
      tauEpsilon = tau - epsilon;

  function Path() {
    (this || _global)._x0 = (this || _global)._y0 = // start of current subpath
    (this || _global)._x1 = (this || _global)._y1 = null; // end of current subpath

    (this || _global)._ = "";
  }

  function path() {
    return new Path();
  }

  Path.prototype = path.prototype = {
    constructor: Path,
    moveTo: function (x, y) {
      (this || _global)._ += "M" + ((this || _global)._x0 = (this || _global)._x1 = +x) + "," + ((this || _global)._y0 = (this || _global)._y1 = +y);
    },
    closePath: function () {
      if ((this || _global)._x1 !== null) {
        (this || _global)._x1 = (this || _global)._x0, (this || _global)._y1 = (this || _global)._y0;
        (this || _global)._ += "Z";
      }
    },
    lineTo: function (x, y) {
      (this || _global)._ += "L" + ((this || _global)._x1 = +x) + "," + ((this || _global)._y1 = +y);
    },
    quadraticCurveTo: function (x1, y1, x, y) {
      (this || _global)._ += "Q" + +x1 + "," + +y1 + "," + ((this || _global)._x1 = +x) + "," + ((this || _global)._y1 = +y);
    },
    bezierCurveTo: function (x1, y1, x2, y2, x, y) {
      (this || _global)._ += "C" + +x1 + "," + +y1 + "," + +x2 + "," + +y2 + "," + ((this || _global)._x1 = +x) + "," + ((this || _global)._y1 = +y);
    },
    arcTo: function (x1, y1, x2, y2, r) {
      x1 = +x1, y1 = +y1, x2 = +x2, y2 = +y2, r = +r;
      var x0 = (this || _global)._x1,
          y0 = (this || _global)._y1,
          x21 = x2 - x1,
          y21 = y2 - y1,
          x01 = x0 - x1,
          y01 = y0 - y1,
          l01_2 = x01 * x01 + y01 * y01; // Is the radius negative? Error.

      if (r < 0) throw new Error("negative radius: " + r); // Is this path empty? Move to (x1,y1).

      if ((this || _global)._x1 === null) {
        (this || _global)._ += "M" + ((this || _global)._x1 = x1) + "," + ((this || _global)._y1 = y1);
      } // Or, is (x1,y1) coincident with (x0,y0)? Do nothing.
      else if (!(l01_2 > epsilon)) ; // Or, are (x0,y0), (x1,y1) and (x2,y2) collinear?
        // Equivalently, is (x1,y1) coincident with (x2,y2)?
        // Or, is the radius zero? Line to (x1,y1).
        else if (!(Math.abs(y01 * x21 - y21 * x01) > epsilon) || !r) {
            (this || _global)._ += "L" + ((this || _global)._x1 = x1) + "," + ((this || _global)._y1 = y1);
          } // Otherwise, draw an arc!
          else {
              var x20 = x2 - x0,
                  y20 = y2 - y0,
                  l21_2 = x21 * x21 + y21 * y21,
                  l20_2 = x20 * x20 + y20 * y20,
                  l21 = Math.sqrt(l21_2),
                  l01 = Math.sqrt(l01_2),
                  l = r * Math.tan((pi - Math.acos((l21_2 + l01_2 - l20_2) / (2 * l21 * l01))) / 2),
                  t01 = l / l01,
                  t21 = l / l21; // If the start tangent is not coincident with (x0,y0), line to.

              if (Math.abs(t01 - 1) > epsilon) {
                (this || _global)._ += "L" + (x1 + t01 * x01) + "," + (y1 + t01 * y01);
              }

              (this || _global)._ += "A" + r + "," + r + ",0,0," + +(y01 * x20 > x01 * y20) + "," + ((this || _global)._x1 = x1 + t21 * x21) + "," + ((this || _global)._y1 = y1 + t21 * y21);
            }
    },
    arc: function (x, y, r, a0, a1, ccw) {
      x = +x, y = +y, r = +r, ccw = !!ccw;
      var dx = r * Math.cos(a0),
          dy = r * Math.sin(a0),
          x0 = x + dx,
          y0 = y + dy,
          cw = 1 ^ ccw,
          da = ccw ? a0 - a1 : a1 - a0; // Is the radius negative? Error.

      if (r < 0) throw new Error("negative radius: " + r); // Is this path empty? Move to (x0,y0).

      if ((this || _global)._x1 === null) {
        (this || _global)._ += "M" + x0 + "," + y0;
      } // Or, is (x0,y0) not coincident with the previous point? Line to (x0,y0).
      else if (Math.abs((this || _global)._x1 - x0) > epsilon || Math.abs((this || _global)._y1 - y0) > epsilon) {
          (this || _global)._ += "L" + x0 + "," + y0;
        } // Is this arc empty? We’re done.


      if (!r) return; // Does the angle go the wrong way? Flip the direction.

      if (da < 0) da = da % tau + tau; // Is this a complete circle? Draw two arcs to complete the circle.

      if (da > tauEpsilon) {
        (this || _global)._ += "A" + r + "," + r + ",0,1," + cw + "," + (x - dx) + "," + (y - dy) + "A" + r + "," + r + ",0,1," + cw + "," + ((this || _global)._x1 = x0) + "," + ((this || _global)._y1 = y0);
      } // Is this arc non-empty? Draw an arc!
      else if (da > epsilon) {
          (this || _global)._ += "A" + r + "," + r + ",0," + +(da >= pi) + "," + cw + "," + ((this || _global)._x1 = x + r * Math.cos(a1)) + "," + ((this || _global)._y1 = y + r * Math.sin(a1));
        }
    },
    rect: function (x, y, w, h) {
      (this || _global)._ += "M" + ((this || _global)._x0 = (this || _global)._x1 = +x) + "," + ((this || _global)._y0 = (this || _global)._y1 = +y) + "h" + +w + "v" + +h + "h" + -w + "Z";
    },
    toString: function () {
      return (this || _global)._;
    }
  };
  exports.path = path;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const path = exports.path,
      __esModule = exports.__esModule;